import React, { useEffect } from 'react';
import { useMerchandiseApi } from '../../apiclients/useMerchandiseApi/useMerchandiseApi';
import DuiNotImplemeted from 'component/dui/dui-not-implemented';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import DuiGrid from 'component/dui/dui-grid';
import DuiTableGrid, {
  ITableColumn
} from 'component/dui/table-grid/dui-table-grid';

const ShirtList = () => {
  const service = useMerchandiseApi();

  const currencyFormatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  });

  const eurPrice = {
    type: 'number',
    width: 130,
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value))
  };

  const columns: ITableColumn[] = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'Firstname', headerName: 'Firstname', hide: true },
    { field: 'Lastname', headerName: 'Lastname', hide: true },
    {
      field: 'fullName',
      headerName: 'Name',
      sortable: true,
      flex: 2,

      valueGetter: (row) => {
        return `${row.Firstname || ''} ${row.Lastname || ''}`;
      }
    },

    {
      field: 'Shirt_Size',
      headerName: 'Größe',
      type: 'number',
      flex: 1
    },
    {
      field: 'Shirt_Quantity',
      headerName: 'Menge',
      type: 'number',
      flex: 1
    },
    {
      field: 'Shirt_Price',
      headerName: 'Preis',
      type: 'currency',
      flex: 1
    },

    {
      field: 'Sum',
      headerName: 'Summe',
      description: 'Berechnete Summe',
      sortable: true,
      type: 'currency',
      flex: 1,
      valueGetter: (row) => row.Shirt_Quantity * row.Shirt_Price
    }
  ];

  useEffect(() => {}, []);

  return (
    <DuiRequestDisplay request={service}>
      <DuiGrid>
        {service.status === 'loaded' && (
          <DuiTableGrid columns={columns} rows={service.payload} />
        )}
        {/* <DataGrid
            rows={data}
            columns={columns}
            pageSize={10}
            id="MerchandiseID"
          /> */}
      </DuiGrid>
    </DuiRequestDisplay>
  );
};

export default ShirtList;
