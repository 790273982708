import useFetchCall from 'apiclients/useFetchCall';

export interface ITrainingsCategoriesResponse {
  key: string;
  value: string;
  TrainingsKategorieID: string;
  KategorieName: string;
  KategorieBeschreibung: string;
  MaxTeilnehmer: number;
  KategorieWeekDay: number;
  SortOrder: number;
  IstAktiv: boolean;
}

export const useTrainingsCategoriesApi = () => {
  const request = {
    EndPoint: '/v1/club/select-options/trainingscategories',
    Method: 'GET',
    RequestBody: '',
    Headers: {}
  };
  const [result] = useFetchCall<ITrainingsCategoriesResponse[]>(request);
  return result;
};
