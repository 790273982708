import { useState } from 'react';
import { ITableColumn } from './dui-table-grid';

type IProps = {
  testid?: string;
  columnsDefinition: ITableColumn[];
  row: any;
};

const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR'
});

export default function DuiTableGridRow({
  columnsDefinition,
  row,
  testid
}: IProps) {
  const [maxCols, setMaxCols] = useState(columnsDefinition.length);
  const [classNames, setClassNames] = useState('');
  const renderColumn = (columnDefinition: ITableColumn) => {
    let value: string | number = '';
    value = row[columnDefinition.field];
    if (columnDefinition.valueGetter) {
      value = columnDefinition.valueGetter(row);
    }
    if (
      columnDefinition.type === 'currency' &&
      value !== '' &&
      value !== undefined
    ) {
      value = currencyFormatter.format(Number(value));
    }
    return (
      <div
        key={`${columnDefinition.field}-${testid}`}
        className={'' + (columnDefinition.hide ? 'hidden' : '')}
      >
        {value}
      </div>
    );
  };
  return (
    <li
      data-testid={testid}
      key={testid}
      className={`grid-cols- grid${maxCols} relative flex justify-between gap-2 gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6`}
    >
      {columnsDefinition.map((column) => {
        return renderColumn(column);
      })}
    </li>
  );
}
