import { useEffect, useState } from 'react';
import { IPyramydMatch } from '../../../component/pyramid/IPyramidMatch';
import { IPyramidResult } from '../../../component/pyramid/IPyramidResult';
import useLocalStorage from '../../useLocalStorage';
import useFetchCall from 'apiclients/useFetchCall';

interface IPyramidResultResponse {
  name: string;
  apiKey: string;
  createdAt: string;
  email: string;
}

export const usePyramidResultApi = () => {
  const [result, setRequest] = useFetchCall<IPyramidResultResponse>();

  const [content, setContent] = useState<IPyramydMatch>();
  const [setApiKey] = useLocalStorage('dhsrcAppApiKey', '');

  useEffect(() => {
    async function fetchData() {
      const path = '/v1/tournament/content/pyramid';
      const pyramidResult: IPyramidResult = {
        playerAID: content?.playerA.id,
        playerBID: content?.playerB.id,
        Satz1A: content?.games[0].pointsA,
        Satz1B: content?.games[0].pointsB,
        Satz2A: undefined,
        Satz2B: undefined,
        Satz3A: undefined,
        Satz3B: undefined,
        Satz4A: undefined,
        Satz4B: undefined,
        Satz5A: undefined,
        Satz5B: undefined
      };
      if (content && content.games.length > 1) {
        pyramidResult.Satz2A = content.games[1].pointsA;
        pyramidResult.Satz2B = content.games[1].pointsB;
      }

      if (content && content.games.length > 2) {
        pyramidResult.Satz3A = content.games[2].pointsA;
        pyramidResult.Satz3B = content.games[2].pointsB;
      }

      if (content && content.games.length > 3) {
        pyramidResult.Satz4A = content.games[3].pointsA;
        pyramidResult.Satz4B = content.games[3].pointsB;
      }
      if (content && content.games.length > 4) {
        pyramidResult.Satz5A = content.games[4].pointsA;
        pyramidResult.Satz5B = content.games[4].pointsB;
      }

      setRequest({
        EndPoint: path,
        Method: 'POST',
        RequestBody: pyramidResult
      });
    }
    if (!content) return;
    fetchData();
  }, [content, setApiKey]);

  return { result, setContent };
};
