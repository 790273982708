import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';

type IProps = {
  children?: ReactNode;
  title?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
};

export default function DuiAlert({
  title = 'Achtung',
  children,
  severity = 'error'
}: IProps) {
  return (
    <div className="rounded-xl border border-gray-200 bg-duiblue-50 px-4  py-5 text-gray-900 dark:border-duiblue-800 dark:bg-duiblue-950 dark:text-duiblue-100 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6">{title}</h3>
        </div>
        <div className="ml-4 mt-2 shrink-0">
          <span>{severity}</span>
          <ExclamationCircleIcon
            className="size-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      </div>
      {children}
    </div>
  );
}
