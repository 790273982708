import React, { useState, useEffect } from 'react';
import { IPyramydPlayer } from './IPyramidPlayer';

import styles from './pyramid.module.css';

type IProps = {
  key: string;
  item: IPyramydPlayer;
};

const PyramidItem = (props: IProps) => {
  const [initial, setInitial] = useState('');

  useEffect(() => {
    let initialtemp = '';
    if (props.item && props.item.firstname) {
      initialtemp = props.item.firstname.substring(0, 1);
    }
    if (props.item && props.item.lastname) {
      initialtemp = initialtemp + props.item.lastname.substring(0, 2);
    }
    setInitial(initialtemp.toUpperCase());
  }, [props.item]);

  return (
    <div
      className={styles.pyramidItem}
      title={`${props.item.firstname} ${props.item.lastname}`}
    >
      <span className={styles.pyramidMobile}>{initial}</span>
      <span className={styles.pyramidNomobile}>
        {props.item.firstname} {props.item.lastname}
      </span>
    </div>
  );
};
export default PyramidItem;
