import { on } from 'events';
import { ReactNode } from 'react';

type IProps = {
  label: string;
  icon?: ReactNode;
  key?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export default function DuiButton({
  label,
  icon,
  onClick,
  key,
  disabled = false
}: IProps) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div>
      <button
        name={key}
        onClick={handleClick}
        disabled={disabled}
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-duiblue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm
          hover:bg-duiblue-500
          focus-visible:outline  focus-visible:outline-2 focus-visible:outline-offset-2   focus-visible:outline-duiblue-600 disabled:cursor-not-allowed disabled:bg-duiblue-200 
          disabled:text-gray-500 dark:bg-duiblue-400 dark:text-duiblue-900 dark:focus-visible:outline-duiblue-400 disabled:dark:bg-duiblue-600 
          disabled:dark:text-gray-300"
      >
        {icon && (
          <div className="-ml-0.5 size-5" aria-hidden="true">
            {icon}
          </div>
        )}
        {label}
      </button>
    </div>
  );
}
