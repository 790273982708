import { useState } from 'react';

function useInput(defaultValue: string | number = '') {
  const [value, setValue] = useState(defaultValue);
  function onChange(e) {
    setValue(e.target.value);
  }
  return {
    value,
    onChange
  };
}

export default useInput;
