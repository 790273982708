import useFetchCall from 'apiclients/useFetchCall';
import ITrainingsDaysResponse from './ITrainingsDaysResponse';

export const useTrainingsDaysNextApi = () => {
  const request = {
    EndPoint: '/v1/club/select-options/trainingstage',
    Method: 'GET',
    RequestBody: '',
    Headers: {}
  };
  const [result] = useFetchCall<ITrainingsDaysResponse[]>(request);
  return result;
};
