import useFetchCall from 'apiclients/useFetchCall';

export interface IMerchendiseResponse {
  id: string;
  MerchandiseID: string;
  Firstname: string;
  Lastname: string;
  Shirt?: string;
  Shirt_Size: string;
  Shirt_Quantity: number;
  Shirt_Price: number;
  Short?: string;
  Short_Size?: string;
  Short_Quantity?: number;
  Short_Price?: number;
  TA?: string;
  TA_Size?: string;
  TA_Quantity?: number;
  TA_Price?: number;
  TA_Hose?: string;
  TA_Hose_Size?: string;
  TA_Hose_Quantity?: number;
  TA_Hose_Price?: number;
  Hood?: string;
  Hood_Size?: string;
  Hood_Quantity?: number;
  Hood_Price?: number;
  Summe: number;
}

export const useMerchandiseApi = () => {
  const request = { EndPoint: '/v1/club/content/merchandise' };
  const [result] = useFetchCall<IMerchendiseResponse[]>(request);
  return result;
};
