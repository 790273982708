import React, { useState, useEffect, useContext } from 'react';

import { useLoginApi } from '../../apiclients/useLoginApi/useLoginApi';
import DuiTextbox from 'component/dui/dui-textbox';
import DuiButton from 'component/dui/dui-button/dui-button';
import { UserContext } from 'userContext';
import { IUser } from 'apiclients/useUserApi/IUser';
import useLocalStorage from 'apiclients/useLocalStorage';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apikey, setApiKey] = useLocalStorage('dhsrcAppApiKey', '');
  const userContext = useContext(UserContext);

  const service = useLoginApi();

  const handleLogin = (payload) => {
    if (userContext?.setUser) {
      userContext?.setUser(payload);
    }
  };

  const handleClick = () => {
    const content = {
      username: username,
      password: password
    };
    service.setContent(content);
  };

  const handleUsernameInput = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (service.result.status === 'loaded') {
      const user: IUser = {
        display_name: service.result.payload.name,
        roles: [],
        memberId: apikey
      };
      setApiKey(service.result.payload.apiKey);
      if (userContext?.setUser) {
        userContext?.setUser(user);
      }
    }
  }, [service.result.status]);

  return (
    <form autoComplete="off">
      <h2>Login</h2>
      <DuiTextbox
        label="Benutzername"
        id="username"
        type="text"
        value={username}
        error={true}
        key="usernameField"
        onChange={handleUsernameInput}
      />
      {/* <TextField
        id="username"
        label="Benutzername"
        value={username}
        onChange={handleUsernameInput}
        margin="dense"
      /> */}
      <br />
      <DuiTextbox
        label="Passwort"
        id="password"
        type="password"
        value={password}
        error={true}
        key="passwortField"
        onChange={handlePasswordChange}
      />
      {/* <TextField
        id="password"
        label="Passwort"
        value={password}
        onChange={handlePasswordChange}
        margin="dense"
        type="password"

      /> */}
      <br />
      <DuiButton label="Login" onClick={handleClick} />

      {/* <Button variant="contained" color="primary" onClick={handleClick} >
        Login
      </Button> */}
      <br />
      {service.result.status === 'loading' ? (
        <div> Anmeldung läuft...</div>
      ) : (
        <div></div>
      )}
      {service.result.status === 'loaded' && service.result.payload.name ? (
        <div>{service.result.payload.name} ist Angemeldet</div>
      ) : (
        <div></div>
      )}
      {service.result.status === 'error' && (
        <div>Error, {service.result.error.message}</div>
      )}
    </form>
  );
};

export default Login;
