import { Routes, Route } from 'react-router-dom';
import Shirt from '../shirt/Shirt';
import Training from '../../pages/Training';
import Pyramid from '../../pages/Pyramid';
import Login from '../login/login';
import LiveResultView from '../live-result/live-result-view';
import ResultForm from '../live-result/result-form';
import PyramidFormPage from '../../pages/PyramidFormPage';
import { ISettings } from 'App';
import TournamentApp from 'pages/tournament/tournament-app';
import { NoMatch } from 'pages/tournament/no-match';
import TournamentSolutionPage from 'pages/tournament/tournament-solution-page';
import TournamentLayout from 'pages/tournament/tournament-layout';
// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"

//<Route path='/roster' component={Roster}/>
//<Route path='/schedule' component={Schedule}/>

function getDefaultComponent(page) {
  let comp = Training;
  switch (page) {
    case 'training':
      comp = Training;
      break;
    case 'login':
      comp = Login;
      break;
    case 'shirt':
      comp = Shirt;
      break;
    case 'pyramid':
      comp = Pyramid;
      break;
    case 'ts':
        comp = TournamentApp;
      break;
    default:
      comp = Login;
      break;
  }
  return comp;
}

type IProps = {
  settings: ISettings;
}



const Main = ({ settings }:IProps) => {
  return (
    <main className="p-2">
      <Routes>
        <Route path="*" Component={getDefaultComponent(settings.page)} />
        <Route path="/shirt" Component={Shirt} />
        <Route path="/training" Component={Training} />
        <Route path="/rangliste" Component={Pyramid} />
        <Route path="/pyramidformpage" Component={PyramidFormPage} />
        <Route path="/login" Component={Login} />
        <Route path="/login" Component={Login} />
        <Route path="/live-result-view" Component={LiveResultView} />
        <Route path="/result" Component={ResultForm} />
        <Route path="/*" element={<NoMatch />} />
        
        
      </Routes>
    </main>
  );
};

export default Main;
