import HeaderTournament from "component/header/header-tournament";
import { Link, Outlet } from "react-router-dom";

const TournamentLayout = () => {

    return (
      <div>
        <HeaderTournament></HeaderTournament>
        
        <hr />
  
        <Outlet />
      </div>
    );
  }

  export default TournamentLayout;