import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState
} from 'react';
import { usePyramidApi } from '../../apiclients/club/pyramid/usePyramidApi';

import useForm from './usePyramidForm';
import validate from './PyramidFormValidationRules';
import { IPyramydPlayer } from './IPyramidPlayer';
import { Link } from 'react-router-dom';
import DuiGrid from 'component/dui/dui-grid';
import DuiButton from 'component/dui/dui-button/dui-button';
import DuiLoading from 'component/dui/dui-loading';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import { PaperAirplaneIcon, PlusIcon } from '@heroicons/react/20/solid';
import DuiSelectGeneric from 'component/dui/select/dui-select-generic';
import { IPyramydGame } from './IPyramidGame';
import PyramidGame from './PyramidGame';
import DuiAlert from 'component/dui/dui-alert';

type IPlayer = {
  firstname: string;
  lastname: string;
};
// https://itnext.io/how-to-build-a-dynamic-controlled-form-with-react-hooks-2019-b39840f75c4f
// https://upmostly.com/tutorials/form-validation-using-custom-react-hooks

const PyramidForm = () => {
  const pyramidApi = usePyramidApi();
  const [playerA, setPlayerA] = useState<IPyramydPlayer | null>(null);
  const [playerB, setPlayerB] = useState<IPyramydPlayer | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [currentGame, setCurrentGame] = useState<IPyramydGame>({
    pointsA: 0,
    pointsB: 0
  });

  const submit = () => {
    setIsSaving(true);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    addGame,
    updatePlayer,
    errors,
    htmlFeedback,
    submittingStatus
  } = useForm(submit, validate);

  const handleChangeGame = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChange(event, Number(event.target.value), event.target.id);
  };

  const AddGame = (game: IPyramydGame) => {
    values.games.push(game);
    setCurrentGame({ pointsA: 0, pointsB: 0 });
  };

  return (
    <div className="flex flex-col gap-3" data-test-id="pyramid-form">
      <form onSubmit={handleSubmit}>
        <DuiRequestDisplay request={pyramidApi}>
          <DuiGrid testid="pyramidForm">
            {pyramidApi.status === 'loaded' && !isGameStarted && (
              <div>
                <h3>Spieler auswählen</h3>
                <DuiSelectGeneric
                  label="Herausforderer"
                  value={playerA}
                  options={pyramidApi.payload}
                  onChange={(newValue) => {
                    if (newValue) {
                      setPlayerA(newValue);
                      updatePlayer(newValue, 'a');
                    }
                  }}
                />

                <DuiSelectGeneric
                  label="Geforderter"
                  value={playerB}
                  options={pyramidApi.payload}
                  onChange={(newValue) => {
                    if (newValue) {
                      setPlayerB(newValue);
                      updatePlayer(newValue, 'b');
                    }
                  }}
                />

                {playerA && playerB && playerA.id !== playerB.id && (
                  <DuiButton
                    label="Spiel starten"
                    onClick={() => setIsGameStarted(true)}
                  />
                )}
              </div>
            )}

            {pyramidApi.status === 'loaded' && isGameStarted && (
              <div className="flex flex-col gap-4">
                <PyramidGame
                  game={currentGame}
                  onCommit={(game) => AddGame(game)}
                  labelA={values.playerA.firstname}
                  labelB={values.playerB.firstname}
                />
              </div>
            )}
          </DuiGrid>
        </DuiRequestDisplay>
      </form>

      {submittingStatus === 'loading' && <DuiLoading loadingType="circle" />}
      {submittingStatus === 'loaded' && (
        <DuiAlert severity="success" title="Spiel gespeichert" />
      )}

      {isSaving ? (
        <DuiLoading loadingType="circle" />
      ) : (
        <>
          <div className="grid grid-flow-col grid-rows-2">
            <div>
              {values.playerA.firstname} {values.playerA.lastname}
            </div>
            <div>
              {values.playerB.firstname} {values.playerB.lastname}
            </div>
            {values.games.map((game, idx) => (
              <>
                <div key={`game-${idx}-a`}>{game.pointsA}</div>
                <div key={`game-${idx}-b`}>{game.pointsB}</div>
              </>
            ))}
          </div>
          <DuiButton
            disabled={
              values.games.length === 0 || submittingStatus === 'loading'
            }
            label="Speichern"
            icon={<PaperAirplaneIcon />}
            onClick={handleSubmit}
          />
          <Link to="/rangliste">
            <DuiButton label="Zur Pyramide" />
          </Link>
        </>
      )}
      {htmlFeedback && (
        <div dangerouslySetInnerHTML={{ __html: htmlFeedback }}></div>
      )}
    </div>
  );
};
export default PyramidForm;
