import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { IService } from 'apiclients/IService';
import { ReactNode } from 'react';
import DuiAlert from './dui-alert';
import DuiLoading from './dui-loading';

type IProps<T> = {
  children?: ReactNode;
  key?: string;
  request: IService<T>;
};

export default function DuiRequestDisplay<T>({
  children,
  key,
  request
}: IProps<T>) {
  if (request.status === 'error') {
    return (
      <div data-testid="DuiRequestDisplay-Error">
        <DuiAlert title="Error, the backend moved to the dark side.">
          <div
            dangerouslySetInnerHTML={{ __html: request.error.message }}
          ></div>
        </DuiAlert>
      </div>
    );
  } else if (request.status === 'loading') {
    return (
      <div data-testid="DuiRequestDisplay-Loading">
        <DuiLoading loadingType="bar" />
      </div>
    );
  } else if (request.status === 'loaded') {
    return (
      <div id={key || 'dui-grid'} data-testid="DuiRequestDisplay" className="">
        {children}
      </div>
    );
  }
}
