import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TournamentLayout from './tournament-layout';
import TournamentSolution from './tournament-solution-page';
import { NoMatch } from './no-match';
import NewTournamentPage from './new-tournament-page';

const TournamentApp = () => {
  return (
    // <BrowserRouter basename="ts">
    <Routes>
      {/* Routes in this app don't need to worry about which URL prefix they are
            mounted at. They can just assume they are mounted at /. Then, if they
            are moved under a different basename later on, all routes and links will
            continue to work. */}
      <Route path="/" element={<TournamentLayout />}>
        <Route index element={<TournamentSolution />} />
        <Route path="/new-tournament" element={<NewTournamentPage />} />
        <Route path=":id" element={<div>Tournament with Id:</div>} />
      </Route>
    </Routes>
    //   </BrowserRouter>
  );
};

export default TournamentApp;
