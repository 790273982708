import { IMember } from 'apiclients/club/training/useMemberApi/IMember';
import { ITrainingsCategoriesResponse } from 'apiclients/club/training/useTrainingCategoriesApi/useTrainingCategoriesApi';
import { ITraining } from './ITraining';
import ITrainingsDaysResponse from 'apiclients/club/training/useTrainingsDaysApi/ITrainingsDaysResponse';

import DuiButton from 'component/dui/dui-button/dui-button';
import useTrainingOverview from 'apiclients/club/training/useTrainingOverview/useTrainingOverview';
import {
  ITrainingContent,
  useTrainingApi
} from 'apiclients/club/training/useTrainingApi/useTrainingApi';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  ArrowPathIcon,
  CheckIcon,
  TrashIcon,
  UserIcon
} from '@heroicons/react/20/solid';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import { useTrainingDeleteApi } from 'apiclients/club/training/useTrainingDeleteApi/useTrainingDeleteApi';
import DuiDialog from 'component/dui/dui-dialog';

type CategoryProps = {
  categories: ITrainingsCategoriesResponse[];
  member: IMember;
  trainingsDay: ITrainingsDaysResponse;
  callbackFnc?: () => void;
};

const CategoryList = (props: CategoryProps) => {
  const [member, setMember] = useState<IMember>(props.member);
  const overviewApi = useTrainingOverview();
  const trainingRemove = useTrainingDeleteApi();
  const trainingApi = useTrainingApi();
  const [showRegisterButtons, setShowRegisterButtons] =
    useState<boolean>(false);
  const [weekday] = useState<number>(dayjs(props.trainingsDay.value).day());
  const [saveIcon, setSaveIcon] = useState<JSX.Element>(
    <CheckIcon className="size-5 flex-none " />
  );
  const [deRegisterIcon, setDeRegisterIcon] = useState<JSX.Element>(
    <TrashIcon className="size-5 flex-none " />
  );

  const maxParticipantsReached = (
    category: ITrainingsCategoriesResponse
  ): boolean => {
    if (category.MaxTeilnehmer === null) {
      return false;
    }
    if (overviewApi.result.status === 'loaded') {
      return (
        overviewApi.result.payload.filter((training) => {
          return training.TrainingsKategorieID === category.key;
        }).length >= category.MaxTeilnehmer
      );
    }
    return false;
  };

  const handleSaveClick = (trainingsCategory) => {
    if (member && props.trainingsDay) {
      const content: ITrainingContent = {
        SpielerID: member.key,
        TrainingDay: props.trainingsDay.key,
        TrainingCategorie: trainingsCategory
      };
      setSaveIcon(
        <ArrowPathIcon className="size-5 animate-spin" aria-hidden="true" />
      );
      trainingApi.fetchData(content).then((response) => {
        setSaveIcon(<CheckIcon className="size-5 flex-none " />);
      });
    }
  };

  useEffect(() => {
    if (trainingApi.result.status === 'loading') {
      setSaveIcon(
        <ArrowPathIcon className="size-5 animate-spin" aria-hidden="true" />
      );
    } else {
      setSaveIcon(<CheckIcon className="size-5 flex-none " />);
    }
    if (trainingApi.result.status === 'loaded') {
      if (trainingApi.result.payload) {
        const addedTraining = trainingApi.result.payload;
        addedTraining.Bild = member.Bild || '';
        addedTraining.Teilnehmer = member.value;
        overviewApi.setTrainingToAdd(addedTraining);
      }
    }
  }, [trainingApi.result.status]);

  useEffect(() => {
    if (trainingRemove.result.status === 'loading') {
      setDeRegisterIcon(
        <ArrowPathIcon className="size-5 animate-spin" aria-hidden="true" />
      );
    } else {
      setDeRegisterIcon(<TrashIcon className="size-5 flex-none " />);
    }
    if (trainingRemove.result.status === 'loaded') {
      if (trainingRemove.result.payload) {
        const removedTraining = trainingRemove.result.payload;
        overviewApi.setTrainingToRemove(removedTraining);
      }
    }
  }, [trainingRemove.result.status]);

  const handleTrashClick = (training: ITraining) => {
    if (training) {
      training.Absage = dayjs().format('YYYY-MM-DD HH:mm:ss');
      setDeRegisterIcon(
        <ArrowPathIcon className="size-5 animate-spin" aria-hidden="true" />
      );
      trainingRemove.setIdToDelete(training.TrainingsTeilnahmeID);
    }
  };

  useEffect(() => {
    if (overviewApi.result.status === 'loaded') {
      if (overviewApi.result.payload) {
        const foundOwnTrainings = overviewApi.result.payload.find(
          (training) => {
            return (
              training.SpielerID === member.key &&
              training.TrainingsTagID === props.trainingsDay.key
            );
          }
        );
        if (foundOwnTrainings) {
          setShowRegisterButtons(false);
        } else {
          setShowRegisterButtons(true);
        }
      } else {
        setShowRegisterButtons(true);
      }
    }
  }, [overviewApi.result]);

  useEffect(() => {
    setMember(props.member);
  }, [props.member]);

  useEffect(() => {
    if (trainingRemove.result.status === 'loading') {
      setDeRegisterIcon(
        <ArrowPathIcon className="size-5 animate-spin" aria-hidden="true" />
      );
    } else {
      setDeRegisterIcon(<TrashIcon className="size-5 flex-none " />);
    }
  }, [trainingRemove.result.status]);

  return (
    <div>
      <h3 className="text-lg font-bold">
        Zeiten{member && <span> für {member.value}</span>}
      </h3>
      <div className="p-2">
        <ul className="devide-2 flex flex-col gap-2 divide-solid">
          {props.categories &&
            props.categories
              .sort((a, b) => a.SortOrder - b.SortOrder) // Sort by Sort Order
              .map((category, index) => {
                if (
                  weekday === category.KategorieWeekDay ||
                  category.KategorieWeekDay === null
                ) {
                  return (
                    <li key={category.key}>
                      <div className="">
                        <div className="relative flex justify-between">
                          <h3 className="text-lg font-bold">
                            {category.value}
                          </h3>
                          {showRegisterButtons && (
                            <DuiButton
                              icon={saveIcon}
                              label="Ich bin dabei"
                              disabled={maxParticipantsReached(category)}
                              onClick={() => handleSaveClick(category.key)}
                            />
                          )}
                        </div>
                        <p>{category.KategorieBeschreibung}</p>

                        <ul
                          role="list"
                          className="divide-2 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                        >
                          <DuiRequestDisplay request={overviewApi.result}>
                            {overviewApi.result.status === 'loaded' &&
                              overviewApi.result.payload.map((training) => {
                                if (
                                  training.TrainingsKategorieID === category.key
                                ) {
                                  return (
                                    <li
                                      key={training.TrainingsTeilnahmeID}
                                      className="relative flex justify-between gap-x-6  px-4 py-5 hover:bg-gray-50 dark:bg-duiblue-700 dark:hover:bg-duiblue-800 sm:px-6"
                                    >
                                      <div className="flex min-w-0 gap-x-4">
                                        {training.Bild ? (
                                          <img
                                            className="size-12 flex-none rounded-full bg-gray-50"
                                            src={training.Bild}
                                            alt=""
                                          />
                                        ) : (
                                          <UserIcon className="size-12 shrink-0 rounded-full" />
                                        )}

                                        <div className="min-w-0 flex-auto">
                                          <div className="text-sm font-semibold leading-6 ">
                                            <span className="absolute inset-x-0 -top-px bottom-0" />
                                            {training.Teilnehmer}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="flex shrink-0 items-center gap-x-4">
                                        {training.SpielerID === member.key && (
                                          <DuiButton
                                            label="Abmelden"
                                            icon={deRegisterIcon}
                                            onClick={() =>
                                              handleTrashClick(training)
                                            }
                                          />
                                        )}
                                      </div>
                                    </li>
                                  );
                                }
                              })}
                          </DuiRequestDisplay>
                        </ul>
                      </div>
                    </li>
                  );
                }
              })}
        </ul>
      </div>
      {trainingRemove.result.status === 'error' && (
        <DuiDialog title="Deletion Error">
          {' '}
          <div
            dangerouslySetInnerHTML={{
              __html: trainingRemove.result.error.message
            }}
          ></div>
        </DuiDialog>
      )}
    </div>
  );
};

export default CategoryList;
