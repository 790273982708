import { useState, useEffect } from 'react';
import useLocalStorage from './useLocalStorage';
import { IService } from './IService';

interface IFetchProps {
  EndPoint: string;
  Method?: string;
  RequestBody?: any;
  Headers?: HeadersInit;
}

function useFetchCall<T>(
  props?: IFetchProps
): [
  IService<T>,
  (props: IFetchProps) => void,
  React.Dispatch<React.SetStateAction<IService<T>>>
] {
  const [result, setResult] = useState<IService<T>>({ status: 'init' });
  const [requestInfo, setRequest] = useState(props);
  const [apiKey] = useLocalStorage('dhsrcAppApiKey');
  const settings = window.erwAppSettings;
  const baseUrl = import.meta.env.VITE_APP_SERVICE_URI
    ? import.meta.env.VITE_APP_SERVICE_URI
    : '/wp-json/dhsrcapi';

  useEffect(() => {
    if (requestInfo == undefined) {
      return;
    }
    if (
      Object.keys(requestInfo).length === 0 &&
      requestInfo.constructor === Object
    ) {
      return;
    }

    if (requestInfo.Method == undefined) {
      requestInfo.Method = 'GET';
    }
    const promise = new Promise((resolve, reject) => {
      const headers: HeadersInit = requestInfo!.Headers
        ? new Headers(requestInfo!.Headers)
        : new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      if (settings && settings.nonce) {
        headers.set('X-WP-Nonce', settings.nonce);
      }
      if (apiKey) {
        headers.append('apikey', apiKey);
      }

      requestInfo!.Headers = headers;

      const fetchURL = baseUrl + requestInfo!.EndPoint;
      const fetchData = {
        body: requestInfo!.RequestBody
          ? JSON.stringify(requestInfo!.RequestBody)
          : null,
        headers: headers,
        method: requestInfo!.Method
      };

      setResult({ status: 'loading' });
      fetch(fetchURL, fetchData)
        .then((response) => {
          switch (response.status) {
            case 200: // OK
            case 201: // CREATED
              response
                .clone()
                .json()
                .then((data) => {
                  resolve(data);
                })
                .catch(async (error) => {
                  console.error(
                    'Fetch Error' + response.status,
                    error,
                    fetchURL
                  );
                  const text = await new Response(response.body).text();
                  const newErr: Error = new Error();
                  newErr.name = 'JSON ERROR';
                  newErr.cause = 'html';
                  newErr.message = text;
                  reject(newErr);
                });
              break;
            case 204: // NO_CONTENT
              resolve([]);
              break;
            default:
              response
                .clone()
                .json()
                .then((data) => {
                  reject(data);
                })
                .catch((error) => {
                  console.error('Fetch Error' + response.status, error);
                  reject(null);
                });
          }
        })
        .catch((error) => {
          console.error('Main Fetch Error', error);
          reject(error);
        });
    });

    promise
      .then(
        (httpResponse: T | unknown) => {
          setResult({ status: 'loaded', payload: httpResponse as T });
        },
        (error) => {
          console.error('Fetch Error promise catch', error);
          setResult({ status: 'error', error: error });
        }
      )
      .catch((error: Error) => {
        setResult({ status: 'error', error: error });
      });
  }, [requestInfo, apiKey, settings, baseUrl]);

  return [result, setRequest, setResult] as [
    IService<T>,
    (props: IFetchProps) => void,
    React.Dispatch<React.SetStateAction<IService<T>>>
  ];
}

export default useFetchCall;
