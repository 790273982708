import { useEffect, useState } from 'react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserIcon
} from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { IMember } from 'apiclients/club/training/useMemberApi/IMember';

function useSelectMember(defaultValue?: IMember) {
  const [value, setValue] = useState(defaultValue);
  function onChange(e: IMember) {
    setValue(e);
  }
  return {
    value,
    onChange
  };
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

type IProps = {
  value?: IMember;
  label: string;
  options?: IMember[];
  onChange: (member?: IMember) => void;
};

export default function DuiSelectMember({
  value,
  label,
  options,
  onChange
}: IProps) {
  const [query, setQuery] = useState('');
  const selectState = useSelectMember(value);

  useEffect(() => {
    onChange(selectState.value);
  }, [selectState.value]);

  const filteredPeople = (): IMember[] => {
    if (options === undefined) return [];
    if (options != undefined && query === '') {
      return options;
    } else {
      const filtered = options.filter((option) => {
        return option.value.toLowerCase().includes(query.toLowerCase());
      });
      return filtered || [];
    }
  };

  return (
    <Combobox as="div" {...selectState}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-duiblue-100">
        {label}
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm  ring-1 
          ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-duiblue-600 dark:bg-duiblue-900 dark:text-duiblue-100 dark:ring-duiblue-700 dark:focus:ring-duiblue-400 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(person: IMember) => {
            return person.value;
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="size-5 text-gray-400 dark:text-duiblue-600"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople() && filteredPeople().length > 0 && (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 
          ring-black ring-opacity-5 focus:outline-none dark:bg-duiblue-900 dark:text-duiblue-100 dark:ring-duiblue-100 sm:text-sm"
          >
            {filteredPeople().map((person) => (
              <Combobox.Option
                key={person.id}
                value={person}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active
                      ? 'bg-duiblue-600 text-white'
                      : 'text-gray-900 dark:text-duiblue-200'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      {person.Bild ? (
                        <img
                          src={person.Bild}
                          alt=""
                          className="size-6 shrink-0 rounded-full"
                        />
                      ) : (
                        <UserIcon className="size-6 shrink-0 rounded-full" />
                      )}
                      <span
                        className={classNames(
                          'ml-3 truncate',
                          selected && 'font-semibold'
                        )}
                      >
                        {person.value}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active
                            ? 'text-white dark:text-duiblue-900'
                            : 'text-duiblue-600 dark:text-duiblue-400'
                        )}
                      >
                        <CheckIcon
                          className="size-5 dark:text-duiblue-100"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
