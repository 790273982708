import useFetchCall from 'apiclients/useFetchCall';
import { ITournamnetsResponse } from './ITournamnetsResponse';

export const useTournamentsApi = () => {
  
  const request = {
    EndPoint: '/v1/tournament/select-options/tournaments',
    Method: 'GET',
    RequestBody: '',
    Headers: {}
  };
  const [result] = useFetchCall<ITournamnetsResponse[]>(request);
  return result;
};
