import { IUser } from 'apiclients/useUserApi/IUser';
import React, { useState } from 'react';

interface IUserContext {
  user: IUser | undefined;
  setUser?: (user: IUser | undefined) => void;
}

export const UserContext = React.createContext<Partial<IUserContext>>({});
interface IUserProvider {
  children: React.ReactNode;
}

const UserProvider: React.FC<IUserProvider> = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>();

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
