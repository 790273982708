import { useState, useEffect } from 'react';
import useFetchCall from 'apiclients/useFetchCall';
import { ITraining } from 'component/training/ITraining';

export const useTrainingDeleteApi = () => {
  const [result, setRequest] = useFetchCall<ITraining>();
  const [idToDelete, setIdToDelete] = useState<string>();

  async function fetchData() {
    const path = '/v1/club/content/training/' + idToDelete;
    setRequest({ EndPoint: path, Method: 'DELETE' });
  }

  useEffect(() => {
    if (idToDelete) {
      fetchData();
    }
  }, [idToDelete]);
  return { result, setIdToDelete };
};
