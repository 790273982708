import { callApi } from '../baseUrl';
import { useState, useEffect } from 'react';
import useLocalStorage from '../useLocalStorage';
import useFetchCall from 'apiclients/useFetchCall';

interface IShirtResponse {
  name: string;
  status: number;
}

interface IShirtRequest {
  firstname: string;
  lastname: string;
  shirtQuantity: number;
  shirtPrice: number;
  shirtSize: string;
}

export const useShirtApi = () => {
  const [result, setRequest] = useFetchCall<IShirtResponse>();
  const [content, setContent] = useState<IShirtRequest>();
  const [apiKey] = useLocalStorage('dhsrcAppApiKey');

  useEffect(() => {
    async function fetchData() {
      const path = '/v1/club/content/merchandise';

      setRequest({ EndPoint: path, Method: 'POST', RequestBody: content });
    }
    if (!content) return;
    fetchData();
  }, [content]);

  return { result, setContent };
};
