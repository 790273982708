import React, { useEffect, useState } from 'react';
import { usePyramidApi } from '../../apiclients/club/pyramid/usePyramidApi';
import PyramidItem from '../pyramid/PyramidItem';
import { IPyramydPlayer } from './IPyramidPlayer';

import styles from './pyramid.module.css';
import DuiRequestDisplay from 'component/dui/dui-request-display';

const PyramidList = () => {
  const service = usePyramidApi();
  const [maxRows, setMaxRows] = useState(0);
  const [rows, setRows] = useState<IPyramydPlayer[][]>([]);

  const getMaxRows = (length: number) => {
    let elementperRowCounter = 0;
    for (let index = length; index > 0; index--) {
      elementperRowCounter++;
      index = index - elementperRowCounter;
    }
    setMaxRows(elementperRowCounter);
    return elementperRowCounter;
  };

  const createRows = (): IPyramydPlayer[][] => {
    const rows: IPyramydPlayer[][] = [];
    let itemIndex = 0;
    if (service.status === 'loaded') {
      // Outer loop to create parent
      for (let i = 1; i <= maxRows + 1; i++) {
        const children: IPyramydPlayer[] = [];

        //Inner loop to create children
        for (let rowItem = 0; rowItem < i; rowItem++) {
          if (service.payload[itemIndex]) {
            //children.push(<PyramidItem key={service.payload[itemIndex].id} item={service.payload[itemIndex]}></PyramidItem>);
            children.push(service.payload[itemIndex]);
            itemIndex++;
          } else {
            // empty items to colplete row
            for (let y = rowItem; y < i; y++) {
              //children.push(<div className="pyramid-item" key={'dummy' + y}>&nbsp;</div>);
              children.push({
                id: 'dmmy' + i + y,
                firstname: 'B',
                lastname: 'Ye',
                image: '',
                position: 900 + i
              });
            }
            rowItem = i;
          }
        }
        //Create the parent and add the children

        rows.push(children);
      }
    }
    return rows;
  };

  useEffect(() => {
    if (service.status == 'loaded') {
      getMaxRows(service.payload.length);
    }
  }, [service, service.status]);

  useEffect(() => {
    if (maxRows > 0) {
      setRows(createRows());
    }
  }, [maxRows]);

  return (
    <div>
      <DuiRequestDisplay request={service}>
        {rows.length > 0 && (
          <div>
            {rows.map((childs, index) => (
              <div className={styles.pyramidRow} key={index}>
                {childs.map((child) => (
                  <PyramidItem key={child.id} item={child}></PyramidItem>
                ))}
              </div>
            ))}
            <h3>Regeln</h3>
            <p>
              Es darf jeder gefordert werden, der links von einem steht oder
              rechts über einem.
            </p>
          </div>
        )}
      </DuiRequestDisplay>
    </div>
  );
};
export default PyramidList;
