import React, { useState } from 'react';
import PlayerView from './player-view';
import GameView from './game-view';
import './live-result.css';

import useCrossTabState from '../../apiclients/useCrossTabState';
const LiveResultView = (props) => {
  const [playerA] = useState({
    name: 'Fedja',
    club: 'DHSRC',
    trikotColor: 'white'
  });
  const [playerB] = useState({
    name: 'Holger',
    club: 'DHSRC',
    trikotColor: 'white'
  });
  const [score] = useCrossTabState('match', {
    a: { game: 1, set: 6 },
    b: { game: 0, set: 6 }
  });
  const [scoreA] = useCrossTabState('scoreA', '0');
  const [scoreB] = useCrossTabState('scoreB', '0');

  return (
    <div className="liveResultView">
      <div className="liveResultView-box">
        <PlayerView {...playerA} />
        <GameView scoreA={scoreA} scoreB={scoreB} />
        <PlayerView {...playerB} />
      </div>
    </div>
  );
};
export default LiveResultView;
