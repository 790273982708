import { useState, useEffect } from 'react';
import useLocalStorage from '../useLocalStorage';
import useFetchCall from 'apiclients/useFetchCall';

interface ILoginResponse {
  name: string;
  apiKey: string;
  createdAt: string;
  email: string;
}

interface ILoginRequest {
  username: string;
  password: string;
}

export const useLoginApi = () => {
  const [result, setRequest] = useFetchCall<ILoginResponse>();

  const [content, setContent] = useState<ILoginRequest>();
  const [setApiKey] = useLocalStorage('dhsrcAppApiKey', '');

  useEffect(() => {
    async function fetchData() {
      const path = '/v1/login';
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };

      setRequest({
        EndPoint: path,
        Method: 'POST',
        RequestBody: content,
        Headers: headers
      });
    }
    if (!content) return;
    fetchData();
  }, [content, setApiKey]);

  return { result, setContent };
};
