import { ISettings } from 'App';
import { useUserApi } from 'apiclients/useUserApi/useUserApi';
import DuiThemeSwitch from 'component/dui/dui-theme-switch/dui-theme-switch';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'userContext';

const HeaderTournament = () => {
  const { user, setUser } = useContext(UserContext);
  const [version] = useState(import.meta.env.REACT_APP_VERSION + ' 2.0');
  const userApi = useUserApi();

  const menuStandard = [
    { name: 'Übersicht', link: '/' },
    { name: 'Spiele und Zeiten', link: '/games' },
    { name: 'Teilnehmer verwalten (Spielerdaten)', link: '/players' }
  ];

  useEffect(() => {
    if (userApi.status === 'loaded' && setUser) {
      setUser(userApi.payload);
    }
  }, [userApi.status]);

  return (
    <header className="App-header">
      <nav className="appNav">
        <div className="logo">{version}</div>
        <div>{user?.display_name}</div>
        <ul>
          {/* Routing is defined in main.js */}
          {menuStandard.map((item, index) => (
            <li key={index}>
              <NavLink to={item.link}>{item.name}</NavLink>
            </li>
          ))}
          <li>
            <DuiThemeSwitch />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderTournament;
