import { createRoot } from 'react-dom/client';
import './index.css';
import App, { ISettings } from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, RouterProvider } from 'react-router-dom';

declare global {
  interface Window {
    erwAppSettings?: {
      page: string;
      hasHeader: boolean;
      nonce?: string;
      color: string;
    };
  }
}

const container = document.getElementById('root-dhsrc-app');
const settings: ISettings = window.erwAppSettings || {
  page: 'ts',
  color: 'blue',
  hasHeader: false
};

//https://reactrouter.com/en/main/start/tutorial


const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter basename={settings.page}>
    <App settings={settings} />
  </BrowserRouter>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
