import { useContext, useState } from 'react';

import { UserContext } from '../../userContext';
import DuiLoading from 'component/dui/dui-loading';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import NewTournamentForm from 'component/tournament/new-tournament-form/new-tournament-form';

const NewTournamentPage = () => {
  const user = useContext(UserContext);
  return (
    <div className="">
      {user ? (
        <div>
          <h1>Neues Turnier</h1>
          <NewTournamentForm />
        </div>
      ) : (
        <DuiLoading loadingType="bar" />
      )}
    </div>
  );
};

export default NewTournamentPage;
