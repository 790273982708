import { useState, useEffect } from 'react';
import { callApi } from 'apiclients/baseUrl';
import { IService } from 'apiclients/IService';
import { ITraining } from 'component/training/ITraining';
import useFetchCall from 'apiclients/useFetchCall';

const useTrainingOverviewApi = () => {
  const [result, setRequest, setResult] = useFetchCall<ITraining[]>();
  const [trainingToAdd, setTrainingToAdd] = useState<ITraining>();
  const [trainingToRemove, setTrainingToRemove] = useState<ITraining>();

  async function fetchData() {
    const path = '/v1/club/content/training/overview';
    setRequest({ EndPoint: path });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (trainingToAdd && result.status === 'loaded') {
      const trainings = [...result.payload, trainingToAdd];
      const srvc: IService<ITraining[]> = {
        status: 'loaded',
        payload: trainings
      };
      setResult(srvc);
    }
  }, [trainingToAdd]);

  useEffect(() => {
    if (trainingToRemove && result.status === 'loaded') {
      const trainings = result.payload.filter(
        (training) =>
          training.TrainingsTeilnahmeID !==
          trainingToRemove.TrainingsTeilnahmeID
      );
      const srvc: IService<ITraining[]> = {
        status: 'loaded',
        payload: trainings
      };
      setResult(srvc);
    }
  }, [trainingToRemove]);

  return { result, setTrainingToAdd, setTrainingToRemove };
};
export default useTrainingOverviewApi;
