import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { ReactNode } from 'react';

type IProps = {
  loadingType: 'circle' | 'bar';
};

export default function DuiLoading({ loadingType = 'circle' }: IProps) {
  return (
    <div className="">
      {loadingType === 'circle' ? (
        <div className="">
          <ArrowPathIcon
            className="size-5 animate-spin text-duiblue-800 dark:text-duiblue-200"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className="h-1 w-full animate-pulse bg-duiblue-600 dark:bg-duiblue-400"></div>
      )}
    </div>
  );
}
