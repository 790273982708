import { useEffect, useState } from 'react';
import DuiButton from 'component/dui/dui-button/dui-button';
import { MinusIcon } from '@heroicons/react/20/solid';

type IProps = {
  point: number;
  label: string;
  onChange: (point: number) => void;
};

export default function DuiPointCounter({
  point = 0,
  label = '',
  onChange
}: IProps) {
  const [count, SetCount] = useState<number>(point);

  const handleClickPlus = () => {
    SetCount(count + 1);
  };

  const handleClickMinus = () => {
    if (count > 0) {
      SetCount(count - 1);
    }
  };

  const handleRange = (e) => {
    const value = Number(e.target.value);
    SetCount(value);
  };

  useEffect(() => {
    onChange(count);
  }, [count]);

  useEffect(() => {
    SetCount(point);
  }, [point]);

  return (
    <div data-testid="dui-point-counter">
      {label && label.length > 0 && (
        <div className="text-2xl font-bold">{label}</div>
      )}
      <div className="flex w-full flex-row content-between items-center justify-between gap-2">
        <div className="w-8 flex-initial text-2xl font-bold">{count}</div>
        <div className=" grow">
          <input
            className="w-full accent-duiblue-700"
            type="range"
            step="1"
            min="0"
            max="11"
            onChange={handleRange}
            value={count}
          />
        </div>
        <div className="flex w-24 flex-initial flex-row gap-2 ">
          <DuiButton label="Punkt" onClick={handleClickPlus}></DuiButton>
          <DuiButton
            label=""
            icon={<MinusIcon className="size-5 " aria-hidden="true" />}
            onClick={handleClickMinus}
          ></DuiButton>
        </div>
      </div>
    </div>
  );
}
