import React, {
  useState,
  useEffect,
  FormEventHandler,
  ChangeEventHandler
} from 'react';
import { usePyramidResultApi } from '../../apiclients/club/pyramid/usePyramidResultApi';
import { IPyramydErrors } from './IPyramidError';
import { IPyramydGame } from './IPyramidGame';
import { IPyramydMatch } from './IPyramidMatch';
import { IPyramydPlayer } from './IPyramidPlayer';
import { IService } from 'apiclients/IService';

const usePyramidForm = (callback: Function, validate: Function) => {
  const service = usePyramidResultApi();
  const [errors, setErrors] = useState<IPyramydErrors>({
    playerA: '',
    playerB: '',
    games: []
  });
  const [values, setValues] = useState<IPyramydMatch>({
    id: '',
    playerA: { id: '', firstname: '', lastname: '', image: '', position: 0 },
    playerB: { id: '', firstname: '', lastname: '', image: '', position: 0 },
    games: []
  });
  const [htmlFeedback, setHtmlFeedback] = useState<string>('');
  const [submittingStatus, setSubmittingStatus] = useState<
    'init' | 'loading' | 'error' | 'loaded'
  >(service.result.status);

  const handleSubmit = () => {
    setErrors(validate(values));
    service.setContent(values);
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
    fieldname: string
  ) => {
    if (!fieldname) {
      return;
    }
    const player = fieldname.split('-')[1];
    const gameIndex = Number(fieldname.split('-')[2]);
    if (player === 'a') {
      values.games[gameIndex].pointsA = newValue;
      const newGames = values.games;
      setValues((prevState) => ({
        ...prevState,
        games: newGames
      }));
    }
    if (player === 'b') {
      values.games[gameIndex].pointsB = newValue;
      const newGames = values.games;
      setValues((prevState) => ({
        ...prevState,
        games: newGames
      }));
    }
  };
  const updatePlayer = (player: IPyramydPlayer | null, aORb: string) => {
    if (player === null) {
      return;
    }
    switch (aORb.toLowerCase()) {
      case 'a':
        setValues((prevState) => ({
          ...prevState,
          playerA: player
        }));
        break;

      case 'b':
        setValues((prevState) => ({
          ...prevState,
          playerB: player
        }));
        break;
      default:
        break;
    }
  };

  const addGame = () => {
    const newGames = values.games;
    newGames.push({ pointsA: 0, pointsB: 0 });
    setValues((prevState) => ({
      ...prevState,
      games: newGames
    }));
  };

  useEffect(() => {
    if (service.result.status === 'error') {
      console.log('Service error', service.result);
      setHtmlFeedback(service.result.error.message);
      setSubmittingStatus('error');
    }
    if (service.result.status === 'loaded') {
      setSubmittingStatus('loaded');
    }
    if (service.result.status === 'loading') {
      setSubmittingStatus('loading');
    }
  }, [service.result.status]);

  return {
    handleChange,
    handleSubmit,
    values,
    addGame,
    updatePlayer,
    errors,
    htmlFeedback,
    submittingStatus
  };
};

export default usePyramidForm;
