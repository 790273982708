import React, { useState } from 'react';
import './live-result.css';

const PlayerView = (props) => {
  const [player] = useState(props);

  return (
    <div className="player">
      <img
        className="player-logo"
        src="https://www.dhsrc.de/wp456der/wp-content/uploads/2015/01/dhsrc_logo.png"
        alt="player logo"
      ></img>
      <div style={{ color: player.trikotColor }}>
        {player.name}
        <br />
        <span className="player-club">{player.club}</span>
      </div>
      <div className="player-trikot" style={{ background: player.trikotColor }}>
        &nbsp;
      </div>
    </div>
  );
};
export default PlayerView;
