import {
  ExclamationCircleIcon,
  MoonIcon,
  SunIcon
} from '@heroicons/react/20/solid';
import ThemeContext from 'context/ThemeContext';
import type { FC } from 'react';
import { useContext } from 'react';

const DuiThemeSwitch: FC = () => {
  const { currentTheme, changeCurrentTheme } = useContext(ThemeContext);

  return (
    <button
      data-testid="switch-theme-btn"
      style={{
        padding: 5,
        borderRadius: 5,
        color: currentTheme === 'light' ? '#AAAAAA' : 'black',
        background: currentTheme === 'light' ? '#333' : '#333'
      }}
      onClick={() =>
        changeCurrentTheme(currentTheme === 'light' ? 'dark' : 'light')
      }
    >
      {currentTheme === 'light' ? (
        <SunIcon className="size-5 " aria-hidden="true" />
      ) : (
        <MoonIcon className="size-5 " aria-hidden="true" />
      )}
    </button>
  );
};

export default DuiThemeSwitch;
