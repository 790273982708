import React, { useContext, useEffect, useState } from 'react';
import ShirtList from './ShirtList';
import './shirt.css';
import ShirtForm from './ShirtForm';
import { UserContext } from '../../userContext';

const Shirt = () => {
  const uc = useContext(UserContext);
  const [showList, setShowList] = useState(true);
  useEffect(() => {
    if (uc.user && uc.user.roles && uc.user.roles.indexOf('editor') > 0) {
      setShowList(true);
    }
  }, [uc]);

  return (
    <div>
      <h1>Shirtbestellung</h1>
      <p>Die neuen Trikots können zum preis von 15 € bestellt werden</p>
      <ShirtForm />
      {showList ? (
        <ShirtList />
      ) : (
        <div>
          {uc && uc.user && <p>{uc.user.display_name}</p>}
          {uc &&
            uc.user &&
            uc.user.roles.map((role) => {
              return <p>{role}</p>;
            })}
          no shirt list
        </div>
      )}
    </div>
  );
};
export default Shirt;
