import { useContext } from 'react';

import { UserContext } from '../userContext';
import DuiLoading from 'component/dui/dui-loading';
import TrainingPoll from 'component/training/TrainingPoll';

// import LinearProgress from "@mui/material/LinearProgress";

const Training = () => {
  const user = useContext(UserContext);

  return (
    <div className="">
      {user ? (
        <div>
          <TrainingPoll></TrainingPoll>
          <div>
            <h3>Ziel und Aufbau des Trainings:</h3>
            <p>
              Wir persönlich glauben, dass ein regelmäßiges und strukturelles
              Training für jeden von euch sehr einflussreich auf euer Spiel sein
              wird.
            </p>
            <p>
              Für diese neue Konzept gibt es einige Spielregeln, die wir euch
              bitten zu beachten:
            </p>
            <dl>
              <li>
                Um den Überblick zu behalten und eine gute Vorbereitung möglich
                zu machen, müsst ihr euch für das Training auf der DHSRC
                Webseite anmelden.
              </li>
            </dl>
          </div>
        </div>
      ) : (
        <DuiLoading loadingType="bar" />
      )}
    </div>
  );
};

export default Training;
