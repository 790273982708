import React, { useState } from 'react';

import { useShirtSizeApi } from 'apiclients/useShirtSizeApi/useShirtSizeApi';
import { useShirtApi } from 'apiclients/useShirtApi/useShirtApi';
import DuiGrid from 'component/dui/dui-grid';
import DuiTextbox from 'component/dui/dui-textbox';
import DuiButton from 'component/dui/dui-button/dui-button';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import DuiSelect, { IOption } from 'component/dui/select/dui-select';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import DuiAlert from 'component/dui/dui-alert';

const NewTournamentForm = () => {
  const [tournamentName, setTournamentNameName] = useState('');

  const handleSaveClick = () => {
    const content = {
      tournamentName: tournamentName
    };
    //  shirtApi.setContent(content);
  };

  return (
    <DuiGrid>
      <DuiTextbox
        id="tournament-name"
        label="Name des Turniers"
        value={tournamentName}
        onChange={() => setTournamentNameName('AA')}
      />
      <DuiButton
        label="Anlegen"
        onClick={handleSaveClick}
        icon={<PaperAirplaneIcon />}
      />
    </DuiGrid>
  );
};

export default NewTournamentForm;
