import React from 'react';
import './live-result.css';
import useCrossTabState from '../../apiclients/useCrossTabState';

type IProps = {
  scoreA: string;
  scoreB: string;
};

const GameView = ({ scoreA, scoreB }: IProps) => {
  const [gameA] = useCrossTabState('gameA', '0');
  const [gameB] = useCrossTabState('gameB', '0');

  return (
    <div className="game">
      <div className="game-all">
        {gameA}:{gameB}
      </div>
      <div className="game-set">
        {scoreA}:{scoreB}
      </div>
    </div>
  );
};
export default GameView;
