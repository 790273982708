import PyramidForm from '../component/pyramid/PyramidForm';

const PyramidFormPage = () => {
  return (
    <div>
      <h1>DHSRC Rangliste Ergebniseintragen</h1>
      <PyramidForm />
    </div>
  );
};

export default PyramidFormPage;
