import { ReactNode, useState } from 'react';
import DuiTableGridRow from './dui-table-grid-row';

const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR'
});

export const eurPrice = {
  type: 'number',
  width: 130,
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value))
};

export interface ITableColumn {
  field: string;
  headerName: string;
  description?: string;
  hide?: boolean;
  sortable?: boolean;
  flex?: number;
  type?: string | 'number' | 'string' | 'date' | 'datetime' | 'time';
  valueGetter?: (params: any) => string | number;
}

type IProps = {
  testid?: string;
  columns: ITableColumn[];
  rows: any[];
};

export default function DuiTableGrid({ columns, rows, testid }: IProps) {
  const [visibleCollumns] = useState(columns.filter((a) => a.hide != true));
  const [maxCols] = useState(visibleCollumns.length);
  return (
    <ul
      role="list"
      className="flex flex-col gap-2 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 dark:divide-duiblue-800 dark:bg-duiblue-950 dark:text-duiblue-50 sm:rounded-xl"
    >
      <li
        data-testid="duiTableGridHeader"
        key="duiTableGridHeader"
        className={`grid-cols- grid${maxCols} relative flex justify-between gap-2 gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6`}
      >
        {visibleCollumns.map((column) => {
          return (
            <div
              key={column.field}
              className={' font-bold ' + (column.hide ? 'hidden' : '')}
            >
              {column.headerName}
            </div>
          );
        })}
      </li>

      {rows.map((row, idx) => {
        return (
          <DuiTableGridRow
            columnsDefinition={visibleCollumns}
            row={row}
            testid={`row-${idx}`}
            key={`row-${idx}`}
          />
        );
      })}
    </ul>
  );
}
