import { useContext, useState } from 'react';

import { UserContext } from '../../userContext';
import DuiLoading from 'component/dui/dui-loading';
import DuiSelect, { IOption } from 'component/dui/select/dui-select';
import { ITournamnetsResponse } from 'apiclients/tournament/useTournamentsApi/ITournamnetsResponse';
import { useTournamentsApi } from 'apiclients/tournament/useTournamentsApi/useTournamentsApi';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import { Link } from 'react-router-dom';
import DuiButton from 'component/dui/dui-button/dui-button';

const TournamentSolutionPage = () => {
  const user = useContext(UserContext);
  const [tournaments, setTournaments] = useState<IOption[]>();
  const [selectedTournament, setSelectedTournament] = useState<IOption>();
  const service = useTournamentsApi();

  return (
    <div className="">
      {user ? (
        <div>
          <h1>Turnier Software</h1>
          <h3>Turnier Name: {selectedTournament?.value}</h3>
          <DuiRequestDisplay request={service}>
            <DuiSelect
              options={
                (service.status == 'loaded' &&
                  service.payload?.map((t: ITournamnetsResponse) => ({
                    value: t.value,
                    key: t.key
                  }))) ||
                []
              }
              label="Bitte Turnier auswählen"
              onChange={setSelectedTournament}
            />
          </DuiRequestDisplay>
          <Link to="/new-tournament">
            <DuiButton label="Neues Tournier" />
          </Link>
        </div>
      ) : (
        <DuiLoading loadingType="bar" />
      )}
    </div>
  );
};

export default TournamentSolutionPage;
