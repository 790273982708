import { useEffect, useState } from 'react';
import './App.css';
import Header from './component/header/Header';
import Main from './component/main/Main';

import UserProvider, { UserContext } from 'userContext';
import ThemeContextWrapper from 'context/ThemeContextWrapper';

export interface ISettings {
  color?: string;
  nonce?: string;
  page: string;
  hasHeader: boolean;
}

type IProps = {
  settings: ISettings;
};  

const App = ({settings}: IProps) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      window.document.documentElement.classList.add('dark');
      setIsDarkMode(true);
    }
  }, []);
  return (
    <ThemeContextWrapper>
      <div className="App bg-white text-gray-900 dark:bg-duiblue-900 dark:text-duiblue-100">
        <UserProvider>
          <Header settings={settings} />
          <hr></hr>
          <Main settings={settings} />
        </UserProvider>
      </div>
    </ThemeContextWrapper>
  );
};

export default App;
