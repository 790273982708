import { IMember } from 'apiclients/club/training/useMemberApi/IMember';
import { useMemberApi } from 'apiclients/club/training/useMemberApi/useMemberApi';
import { useTrainingsCategoriesApi } from 'apiclients/club/training/useTrainingCategoriesApi/useTrainingCategoriesApi';
import { useTrainingsDaysNextApi } from 'apiclients/club/training/useTrainingsDaysApi/useTrainingsDayNextApi';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import DuiSelectMember from 'component/dui/select/dui-select-member';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'userContext';
import CategoryList from './CategoryList';

dayjs.locale('de');

const TrainingPoll = () => {
  const uc = useContext(UserContext);
  const [enableMemberSelect, setEnableMemberSelect] = useState(false);
  const [members, setMembers] = useState<IMember[]>();
  const [selectedMember, setSelectedMember] = useState<IMember>();

  const trainigsDaysApi = useTrainingsDaysNextApi();
  const memberApi = useMemberApi();
  const categoryApi = useTrainingsCategoriesApi();

  const handleSelectMember = (member?: IMember | undefined) => {
    setSelectedMember(member);
  };

  useEffect(() => {
    if (uc.user && uc.user.roles && uc.user.roles.indexOf('DHSRC Admin') >= 0) {
      setEnableMemberSelect(true);
    }
    if (uc.user && uc.user.memberId && uc.user.memberId.length >= 0) {
      const findMember = members?.find(
        (member) => member.key === uc!.user!.memberId
      );
      if (findMember) {
        setSelectedMember(findMember);
      }
    }
  }, [uc, uc.user, members]);

  useEffect(() => {
    if (memberApi.status == 'loaded' && memberApi.payload) {
      setMembers(memberApi.payload);
    }
  }, [memberApi]);

  return (
    <div className="">
      <h1>Anmeldung zum Training</h1>
      {enableMemberSelect && (
        <DuiRequestDisplay request={memberApi}>
          <DuiSelectMember
            label="Mitglied auswählen"
            value={selectedMember}
            options={members}
            onChange={handleSelectMember}
          />
        </DuiRequestDisplay>
      )}
      <DuiRequestDisplay request={trainigsDaysApi}>
        {trainigsDaysApi.status === 'loaded' &&
          trainigsDaysApi.payload.length > 0 && (
            <DuiRequestDisplay request={categoryApi}>
              {categoryApi.status === 'loaded' && (
                <div>
                  {trainigsDaysApi.payload.map((trainingsDay) => {
                    return (
                      <div key={trainingsDay.key}>
                        <h2>
                          {dayjs(trainingsDay.value).format('dddd, DD. MMMM')}
                        </h2>

                        <CategoryList
                          categories={categoryApi.payload}
                          member={selectedMember!}
                          trainingsDay={trainingsDay}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </DuiRequestDisplay>
          )}
      </DuiRequestDisplay>
    </div>
  );
};

export default TrainingPoll;
