import React, { useState } from 'react';

import { useShirtSizeApi } from '../../apiclients/useShirtSizeApi/useShirtSizeApi';
import { useShirtApi } from '../../apiclients/useShirtApi/useShirtApi';
import DuiGrid from 'component/dui/dui-grid';
import DuiTextbox from 'component/dui/dui-textbox';
import DuiButton from 'component/dui/dui-button/dui-button';
import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import DuiSelect, { IOption } from 'component/dui/select/dui-select';
import DuiRequestDisplay from 'component/dui/dui-request-display';
import DuiAlert from 'component/dui/dui-alert';

const ShirtForm = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [size, setSize] = useState<IOption>({
    key: 'XL',
    value: 'XL'
  } as IOption);
  const [quantity, setQuantity] = useState(0);
  const [price] = useState(27.47);
  const [errorForm] = useState(null);

  const shirtSizeApi = useShirtSizeApi();
  const shirtApi = useShirtApi();

  const handleSaveClick = () => {
    const content = {
      firstname: firstName,
      lastname: lastName,
      shirtQuantity: quantity,
      shirtPrice: price,
      shirtSize: size.value
    };
    shirtApi.setContent(content);
  };

  const handleLastNameInput = (e) => {
    setLastName(e.target.value);
  };

  const handleFirstNameInput = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleSelectSizeChange = (value) => {
    setSize(value);
  };
  if (shirtSizeApi.status === 'error') {
    //if (shirtSizeApi.result.status === 'error') {
    return <div>Error: {shirtSizeApi.error.message}</div>;
    // return <div>Error: {shirtSizeApi.result.error.message}</div>;
  } else if (shirtSizeApi.status === 'loading') {
    return <div>Loading...</div>;
  } else {
    return (
      <form autoComplete="off">
        <DuiGrid>
          <DuiTextbox
            id="first-name"
            label="Vorname"
            value={firstName}
            onChange={handleFirstNameInput}
          />
          <DuiTextbox
            id="standalastrd-name"
            label="Nachname"
            value={lastName}
            onChange={handleLastNameInput}
          />
          <DuiSelect
            label="Größe"
            value={size}
            options={
              shirtSizeApi.status == 'loaded' ? shirtSizeApi.payload : undefined
            }
            onChange={handleSelectSizeChange}
          />
          <DuiTextbox
            id="quantity-number"
            label="Menge"
            value={quantity}
            onChange={handleChangeQuantity}
            type="number"
            InputLabelProps={{
              shrink: true
            }}
          />
          <DuiButton
            label="Bestellen"
            onClick={handleSaveClick}
            icon={<PaperAirplaneIcon />}
          />
        </DuiGrid>
        <DuiRequestDisplay request={shirtApi.result}>
          <DuiAlert severity="success" title="Geklappt">
            Erfolgreich gespeichert
          </DuiAlert>
        </DuiRequestDisplay>
      </form>
    );
  }
};

export default ShirtForm;
