import PyramidList from '../component/pyramid/PyramidList';
import { Link } from 'react-router-dom';
import DuiButton from 'component/dui/dui-button/dui-button';

const Pyramid = () => {
  return (
    <div>
      <h1>DHSRC Rangliste</h1>
      <PyramidList />
      <Link to="/PyramidFormPage">
        <DuiButton label="Ergebnis eintragen"></DuiButton>
      </Link>
    </div>
  );
};

export default Pyramid;
