import { ISettings } from 'App';
import { useUserApi } from 'apiclients/useUserApi/useUserApi';
import DuiThemeSwitch from 'component/dui/dui-theme-switch/dui-theme-switch';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'userContext';

type IProps = {
  settings: ISettings;
}

const Header = ({settings}: IProps) => {
  const { user, setUser } = useContext(UserContext);
  const [showHeader, setShowHeader] = useState(settings.hasHeader);
  const [version] = useState(import.meta.env.REACT_APP_VERSION+'-'+import.meta.env.VITE_APP_VERSION);
  const userApi = useUserApi();

  const menuStandard = [
    { name: 'Training', link: '/training' },
    { name: 'Shirt', link: '/shirt' },
    { name: 'Pyramide', link: '/pyramid' },
    { name: 'Pyramide Form', link: '/pyramidformpage' },
    { name: 'Login', link: '/login' },
    { name: 'LR V', link: '/live-result-view' },
    { name: 'Tournament Solution', link: '/ts' },
    { name: 'Form', link: '/result' }
  ];

  useEffect(() => {
    if (userApi.status === 'loaded' && setUser) {
      setUser(userApi.payload);
    }
  }, [userApi.status]);

  const handleClick = () => {
    setShowHeader(!showHeader);
  };

  return (
    <header className="App-header">
      {showHeader && (
        <nav className="appNav">
          <div className="logo" onClick={handleClick}>
            {version}
          </div>
          <div>{user?.display_name}</div>
          <ul>
            {/* Routing is defined in main.js */}
            {menuStandard.map((item, index) => (
              <li key={index}>
                <NavLink to={item.link}>{item.name}</NavLink>
              </li>
            ))}
            <li>
              <DuiThemeSwitch />
            </li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
