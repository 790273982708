import { useState, useEffect } from 'react';
import ThemeContext from './ThemeContext';

const ThemeContextWrapper = ({ children }) => {
  const persistedTheme: string | null = localStorage.getItem('theme');
  const [theme, setTheme] = useState(persistedTheme || 'light');

  const changeCurrentTheme = (newTheme: 'light' | 'dark') => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const darkThemeListener = (e) => e.matches && changeCurrentTheme('dark');
    const lightThemeListener = (e) => e.matches && changeCurrentTheme('light');
    const mdark = window.matchMedia('(prefers-color-scheme: dark)');
    const mlight = window.matchMedia('(prefers-color-scheme: light)');
    if (mdark.matches) {
      changeCurrentTheme('dark');
    } else if (mlight.matches) {
      changeCurrentTheme('light');
    }
    mdark.addEventListener('change', darkThemeListener);
    mlight.addEventListener('change', lightThemeListener);
    return () => {
      // cleanup event listeners
      mdark.removeEventListener('change', darkThemeListener);
      mlight.removeEventListener('change', lightThemeListener);
    };
  }, []);

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').matches;
    if (theme === 'light') document.body.classList.remove('dark');
    else document.body.classList.add('dark');
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ currentTheme: theme, changeCurrentTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextWrapper;
