import { IPyramydPlayer } from 'component/pyramid/IPyramidPlayer';
import useFetchCall from 'apiclients/useFetchCall';

// https://dev.to/camilomejia/fetch-data-with-react-hooks-and-typescript-390c

export const usePyramidApi = () => {
  const request = { EndPoint: '/v1/tournament/content/pyramid' };
  const [result] = useFetchCall<IPyramydPlayer[]>(request);
  return result;
};
