import { useState, useEffect } from 'react';

import { IPyramydGame } from './IPyramidGame';
import DuiPointCounter from 'component/dui/point-counter/dui-point-counter';
import DuiButton from 'component/dui/dui-button/dui-button';

type IProps = {
  game: IPyramydGame;
  labelA: string;
  labelB: string;
  onCommit: (game: IPyramydGame) => void;
};

const PyramidGame = (props: IProps) => {
  const [game, setGame] = useState<IPyramydGame>(props.game);

  useEffect(() => {
    setGame(props.game);
  }, [props.game]);

  return (
    <div className="flex flex-col gap-4" data-test-id="pyramid-game">
      <div className="items-center text-center text-4xl font-bold">
        <span className="">{game.pointsA}</span>:
        <span className="">{game.pointsB}</span>
      </div>
      <DuiPointCounter
        label={props.labelA}
        point={game.pointsA}
        onChange={(e) => setGame({ ...game, pointsA: e })}
      ></DuiPointCounter>
      <DuiPointCounter
        label={props.labelB}
        point={game.pointsB}
        onChange={(e) => setGame({ ...game, pointsB: e })}
      ></DuiPointCounter>
      <DuiButton
        label="Satz ergebnis hinzufügen"
        onClick={() => props.onCommit(game)}
        disabled={
          game.pointsA === game.pointsB ||
          (game.pointsA < 11 && game.pointsB < 11)
        }
      ></DuiButton>
    </div>
  );
};
export default PyramidGame;
