import { ReactNode, useState } from 'react';

type IProps = {
  children?: ReactNode;
  testid?: string;
};

export default function DuiGrid({ children, testid }: IProps) {
  return (
    <div
      data-testid={testid}
      className="grid grid-cols-1 gap-2 bg-white dark:bg-black dark:text-duiblue-100 md:grid-cols-2"
    >
      {children}
    </div>
  );
}
