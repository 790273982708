import { useState, useEffect } from 'react';
import useFetchCall from 'apiclients/useFetchCall';
import { ITraining } from 'component/training/ITraining';

export interface ITrainingContent {
  TrainingDay: string | undefined;
  SpielerID: string;
  TrainingCategorie: string;
}

export const useTrainingApi = () => {
  const [result, setRequest] = useFetchCall<ITraining>();
  const [content, setContent] = useState<ITrainingContent>();

  const fetchData = async (content: ITrainingContent) => {
    const path = '/v1/club/content/training';
    setRequest({ EndPoint: path, Method: 'POST', RequestBody: content });
  };

  useEffect(() => {
    if (!content || !content.TrainingDay) return;

    fetchData(content);
  }, [content]);
  return { result, setContent, fetchData };
};
