import { IPyramydErrors } from './IPyramidError';
import { IPyramydMatch } from './IPyramidMatch';

export default function validate(values: IPyramydMatch) {
  const errors: IPyramydErrors = { playerA: '', playerB: '', games: [] };

  if (!values.playerA) {
    errors.playerA = 'Player A is required';
  }
  if (!values.playerB) {
    errors.playerB = 'Player B is required';
  }

  errors.games = [];
  values.games.forEach((game) => {
    const gameError: IPyramydErrors = { playerA: '', playerB: '', games: [] };
    if (game.pointsA < 0) {
      gameError.playerA = 'nur positive werte';
    }
    if (game.pointsB < 0) {
      gameError.playerB = 'nur positive werte';
    }
    if (game.pointsA === game.pointsB) {
      gameError.playerA = 'Ergebnis darf nicht gleich sein';
      gameError.playerB = 'Ergebnis darf nicht gleich sein';
    }
    errors.games.push(gameError);
  });

  return errors;
}
