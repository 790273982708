import { useEffect, useState } from 'react';
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserIcon
} from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { IMember } from 'apiclients/club/training/useMemberApi/IMember';
import { IPyramydPlayer } from 'component/pyramid/IPyramidPlayer';

export interface IOption {
  key: string;
  value: string;
}

function useSelect<T>(defaultValue?: T) {
  const [value, setValue] = useState(defaultValue);
  function onChange(e: T) {
    setValue(e);
  }
  return {
    value,
    onChange
  };
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

type IProps<T> = {
  value?: T;
  label: string;
  options?: T[];
  onChange: (selected?: T) => void;
};

function isIOption(obj: any): obj is IOption {
  return obj && obj.hasOwnProperty('value') && obj.hasOwnProperty('key');
}
function isIPyramydPlayer(obj: any): obj is IPyramydPlayer {
  return (
    obj && obj.hasOwnProperty('firstname') && obj.hasOwnProperty('lastname')
  );
}

function filterOption<T>(option: T, query: string): boolean {
  if (isIOption(option)) {
    return option.value.toLowerCase().includes(query.toLowerCase());
  }
  switch (typeof option) {
    case 'string':
      return option.toLowerCase().includes(query.toLowerCase());
    case 'object':
      if (isIOption(option)) {
        return option.value.toLowerCase().includes(query.toLowerCase());
      }
    default:
      return false;
  }
}

function renderOption<T>(option: T, selected: boolean): React.ReactNode {
  let text = '-';
  if (isIOption(option)) {
    text = option.value;
  }
  if (isIPyramydPlayer(option)) {
    text = option.firstname + ' ' + option.lastname;
  }
  return (
    <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
      {text}
    </span>
  );
}

function renderSelected<T>(option: T): string {
  let text = '-';
  if (isIOption(option)) {
    text = option.value;
  }
  if (isIPyramydPlayer(option)) {
    text = option.firstname + ' ' + option.lastname;
  }
  return text;
}

export default function DuiSelectGeneric<T>({
  value,
  label,
  options,
  onChange
}: IProps<T>) {
  const [query, setQuery] = useState('');
  const selectState = useSelect(value);

  useEffect(() => {
    onChange(selectState.value as T);
  }, [selectState.value]);

  const filteredOptions = (): T[] => {
    if (options === undefined) return [];
    if (options != undefined && query === '') {
      return options;
    } else {
      const filtered = options.filter((option) => {
        return filterOption(option, query);
      });
      return filtered || [];
    }
  };

  return (
    <Combobox as="div" {...selectState}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-duiblue-100">
        {label}
      </Combobox.Label>
      <div className="relative mt-2 ">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm  ring-1 
          ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-duiblue-600 dark:bg-duiblue-900 dark:text-duiblue-100 dark:ring-duiblue-700 dark:focus:ring-duiblue-400 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option: T) => {
            return renderSelected(option);
          }}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="size-5 text-gray-400 dark:text-duiblue-600"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredOptions() && filteredOptions().length > 0 && (
          <Combobox.Options
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 
          ring-black ring-opacity-5 focus:outline-none dark:bg-duiblue-900 dark:text-duiblue-100 dark:ring-duiblue-100 sm:text-sm"
          >
            {filteredOptions().map((option, idx) => (
              <Combobox.Option
                key={idx}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active
                      ? 'bg-duiblue-600 text-white'
                      : 'text-gray-900 dark:text-duiblue-200'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      {renderOption(option, selected)}
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active
                            ? 'text-white dark:text-duiblue-900'
                            : 'text-duiblue-600 dark:text-duiblue-400'
                        )}
                      >
                        <CheckIcon
                          className="size-5 dark:text-duiblue-100"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
