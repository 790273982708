import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import useInput from 'apiclients/useInput';
import { useEffect, useState } from 'react';

type IProps = {
  id: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  label: string;
  error?: boolean;
  helperText?: string;
  key?: string;
  inputProps?: { 'data-idx': number; className: string };
  InputLabelProps?: { shrink: boolean };
  testid?: string;
};

export default function DuiTextbox({
  id,
  type = 'text',
  label,
  value,
  helperText,
  error,
  onChange,
  inputProps,
  testid
}: IProps) {
  const [name] = useState(id);
  const [placeholder] = useState(label);
  const inputState = useInput(value);
  const [autoCompleteType, setAutoCmpleteType] = useState(id);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  useEffect(() => {
    onChange({
      target: { value: inputState.value, id: id }
    } as React.ChangeEvent<HTMLInputElement>);
  }, [inputState.value]);

  useEffect(() => {
    if (type === 'password') {
      autoCompleteType === 'current-password';
    }
  }, [type]);

  return (
    <div className="">
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-6 text-gray-900 dark:text-duiblue-100"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          id={id}
          type={type}
          name={name}
          className="block w-full rounded-md border-0 py-1.5 pr-10 text-duiblue-900 ring-1 ring-inset ring-duiblue-300 placeholder:text-duiblue-300 focus:ring-2 focus:ring-inset focus:ring-duiblue-500 dark:bg-duiblue-900 dark:text-duiblue-100 dark:ring-duiblue-700 dark:placeholder:text-duiblue-700 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          aria-invalid={error}
          aria-describedby={`${name}-error`}
          aria-description={`data-idx=${inputProps?.['data-idx']} class=${inputProps?.className}  `}
          data-testid={testid}
          autoComplete={autoCompleteType}
          {...inputState}
        />
      </div>
      <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
        {helperText}
      </p>
    </div>
  );
}
