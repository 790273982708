import React from 'react';
import './live-result.css';
import useCrossTabState from '../../apiclients/useCrossTabState';
const ResultForm = (props) => {
  const [scoreA, setScoreA] = useCrossTabState('scoreA', '0');
  const [scoreB, setScoreB] = useCrossTabState('scoreB', '0');
  const [gameA] = useCrossTabState('gameA', '0');
  const [gameB] = useCrossTabState('gameB', '0');

  const handleClickB = () => {
    let tempScoreB = parseInt(scoreB);
    tempScoreB++;
    setScoreB(tempScoreB.toString());
  };

  const handleClickA = () => {
    let tempScoreA = parseInt(scoreA);
    tempScoreA++;
    setScoreA(tempScoreA.toString());
  };

  const handleReset = () => {
    setScoreA('0');
    setScoreB('0');
  };
  return (
    <div className="resultForm">
      <div className="game-all">
        {gameA}:{gameB}
      </div>
      <div className="game-set">
        {scoreA}:{scoreB}
      </div>
      <button onClick={handleClickA}>A +</button>
      <button onClick={handleClickB}>B +</button>
      <button onClick={handleReset}>Reset</button>
    </div>
  );
};

export default ResultForm;
